export default class Breakpoint {
  constructor() {
    this.breakpoints = [
      {breakpoint:0,      name:'xs'},
      {breakpoint:576,    name:'sm'},
      {breakpoint:768,    name:'md'},
      {breakpoint:992,    name:'lg'},
      {breakpoint:1200,   name:'xl'}
    ];
    this.breakpoint = 'xs';
    this.wait = null;

    this.calculateBreakpoint();

    window.addEventListener('resize', function () {
      document.body.classList.add('resize');
      clearTimeout(this.wait);
      this.wait = setTimeout(function () {
        document.body.classList.remove('resize');
      }, 1000);
      this.calculateBreakpoint();
    }.bind(this));
  }

  calculateBreakpoint(){
    let currentBreakpoint = this.breakpoint;
    this.breakpoint = this.breakpoints[0].name;
    $(this.breakpoints).each(function(index, breakpoint){
      if (window.innerWidth > breakpoint.breakpoint){
        this.breakpoint = breakpoint.name;
      }
    }.bind(this));

    if (currentBreakpoint != this.breakpoint){
      try{
        let event = new CustomEvent('breakpoint', { 'detail': this.breakpoint });
        window.dispatchEvent(event);
      }catch(e){
        console.log(e);
      }
    }
  }
}
