// Nodes modules
import './modules/@tc';
import 'classlist-polyfill';
import 'object-fit-polyfill';
import animateScrollTo from 'animated-scroll-to';
//import * as Swiper from 'swiper/dist/js/swiper.js';
import * as Stickyfill from 'stickyfilljs';

window.addEventListener('load', function(){

    // Nav external link animation
    function mouseOver(){
        $(this).parent().find('span').css('opacity','1');
    }

    function mouseOut() {
        $(this).parent().find('span').css('opacity','0.25');
    }

   $('.nav li:first-child a').bind('mouseover', mouseOver).bind('mouseout', mouseOut);

    // Form 
    /// Put "Etape" text in progress sidebar
    $('form .form-actions').insertAfter($('form .js-form-wrapper .container-bg-white'));

    var totalStep = $('.progress-step').length-1;
    var stepNumberActif = $('.progress-step.is-active .progress-marker').attr('data-text');

    $('.progress-marker').each(function (e) {
        var stepNumber = $(this).attr('data-text');

        $(this).append("<p>" + 'Etape 0' + stepNumber + "<span class='ontotal-step d-md-none'> / 0" + totalStep + "</span>" + "</p>");
    });

    var progressionWidth = (stepNumberActif / totalStep) * 100;

    $( "<style>.large-container-form:before {width: " + progressionWidth + "%;}</style>" ).appendTo("head");


    /// Checkbox
    if($('form .long-description').length > 0) {
        $('.long-description .form-check > label').each(function (e) {
            $(this).append('<span></span>');
        });
    }

    if($('.js-webform-checkboxes').length > 0) {
        $('.js-webform-checkboxes .form-check .form-check-label').each(function (e) {
            $(this).append('<span></span>');
        });
    }

    $('[id^=edit-formules-omnium-complte]').click(function(){
        if($(this).is(':checked') && !$('[id^=edit-formules-omnium-partielle]').is(':checked')) {
            $('[id^=edit-formules-omnium-partielle]').trigger('click');
        }
     });

    $('[id^=edit-formules-omnium-complte]').on("change", function() {
        if($('[id^=edit-formules-omnium-complte]').is(':checked')) {
            $('[id^=edit-formules-omnium-partielle]').css('pointer-events', 'none');
        } else {
            $('[id^=edit-formules-omnium-partielle]').css('pointer-events', 'auto');
        }
    });

    /// Radio
    $('.form-check input.form-radio').each(function (e) {
        $(this).insertBefore($(this).parent());
    });

    $(".js-webform-type-radios").each(function(index) {
        $(this).wrapInner("<div class='form-element-wrapper' />")
    });

    /// Add focus on select option:selected
    function requiredField() {
        if($(this).find('option:selected').val() != '') {
            $(this).addClass('focus');
        } else {
            $(this).removeClass('focus');
        }

        if($(this).parentsUntil('.container-inline').find('select option:selected').val() == '') {
            //console.log('not-good');
        }
    }

    $('select').each(function (e) {
        $(this).on("keydown keyup change",requiredField);
    });

    $('.js-form-item:not(.date-select) select').each(function (e) {
        $(this).focusout(function (e) {
            if($(this).find('option:selected').val() == '') {
                $(this).addClass('error');
            } else {
                $(this).removeClass('error');
            }
        });
    });

    $('.date-select > label').click(function(){
        $(this).parent().addClass('input--filled');
    });

    $('.date-select select').on('keyup keypress blur change', function (e) {
        $(this).closest('.date-select').addClass('input--filled');
    });

    $('.date-select select').each(function (e) {
        $(this).focusout(function (e) {
            if($(this).find('option:selected').val() == '') {
                $(this).addClass('error');
            } else {
                $(this).removeClass('error');
            }
        });
    });

    $('.date-select').each(function (e) {
        if($(this).find('select').hasClass('error')) {
            $(this).addClass('error');
        }
    });
   
    $('.js-form-type-select select').click(function (e) {
        $(this).parent().addClass('input--filled');
    });

    $('.js-form-type-select select').click(function (e) {
        $(this).parents('.js-form-type-select').addClass('input--filled');
    });

    // UPDATE 27/09/2022
    



    // UPDATE 17/08/2022
    var attr;
    attr = $(".webform-progress + .js-form-wrapper").attr('data-drupal-selector');

    var lastDateEncoded = new Date( "01 " + "Jan 2021");
    var dateFrom = lastDateEncoded.getFullYear();
    var dateTo = (new Date).getFullYear();
    var arrDates = Array();
    for(var i = dateFrom; i <= dateTo; i++) arrDates.push(i);

    if(attr == "edit-vos-coordonnees") {
        arrDates.forEach(function(val) {                     
            if($("[id^=edit-permis-year] option:selected").val() != val
            && $("[id^=edit-permis-year] option:selected").val() != val + 1
            && $("[id^=edit-permis-year] option:selected").val() != val + 2
            && $("[id^=edit-permis-year] option:selected").val() != val + 3
            && $("[id^=edit-permis-year] option:selected").val() != val + 4
            && $("[id^=edit-permis-year] option:selected").val() != val + 5
            && $("[id^=edit-permis-year] option:selected").val() != val + 6
            && $("[id^=edit-permis-year] option:selected").val() != val + 7
            && $("[id^=edit-permis-year] option:selected").val() != val + 8
            && $("[id^=edit-permis-year] option:selected").val() != val + 9
            && $("[id^=edit-permis-year] option:selected").val() != val + 10
            && $("[id^=edit-permis-year] option:selected").val() != val + 11
            && $("[id^=edit-permis-year] option:selected").val() != val + 12
            && $("[id^=edit-permis-year] option:selected").val() != val + 13
            && $("[id^=edit-permis-year] option:selected").val() != val + 14
            && $("[id^=edit-permis-year] option:selected").val() != val + 15
            && $("[id^=edit-permis-year] option:selected").val() != val + 16
            && $("[id^=edit-permis-year] option:selected").val() != val + 17
            && $("[id^=edit-permis-year] option:selected").val() != val + 18
            && $("[id^=edit-permis-year] option:selected").val() != val + 19
            && $("[id^=edit-permis-year] option:selected").val() != val + 20
            && $("[id^=edit-permis-year] option:selected").val() != val + 21
            && $("[id^=edit-permis-year] option:selected").val() != val + 22
            && $("[id^=edit-permis-year] option:selected").val() != val + 23
            && $("[id^=edit-permis-year] option:selected").val() != val + 24
            && $("[id^=edit-permis-year] option:selected").val() != val + 25
            && $("[id^=edit-permis-year] option:selected").val() != val + 26
            && $("[id^=edit-permis-year] option:selected").val() != val + 27
            && $("[id^=edit-permis-year] option:selected").val() != val + 28
            && $("[id^=edit-permis-year] option:selected").val() != val + 29
            && $("[id^=edit-permis-year] option:selected").val() != val + 30

            ) {
                $("[id^=edit-permis-year] option").eq(1).before($('<option>', {
                    value: val,
                    text: val,
                }));   
            }            
        });

        arrDates.forEach(function(val) {                     
            if($("[id^=edit-date-naissance-year] option:selected").val() != val
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 1
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 2
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 3
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 4
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 5
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 6
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 7
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 8
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 9
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 10
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 11
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 12
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 13
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 14
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 15
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 16
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 17
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 18
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 19
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 20
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 21
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 22
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 23
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 24
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 25
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 26
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 27
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 28
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 29
            && $("[id^=edit-date-naissance-year] option:selected").val() != val + 30

            ) {
                $("[id^=edit-date-naissance-year] option").eq(1).before($('<option>', {
                    value: val,
                    text: val,
                }));   
            }            
        });
    }

    if(attr == "edit-votre-vehicule") {
        arrDates.forEach(function(val) {                     
            if($("[id^=edit-annee-construction-year] option:selected").val() != val
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 1
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 2
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 3
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 4
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 5
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 6
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 7
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 8
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 9
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 10
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 11
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 12
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 13
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 14
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 15
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 16
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 17
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 18
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 19
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 20
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 21
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 22
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 23
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 24
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 25
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 26
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 27
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 28
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 29
            && $("[id^=edit-annee-construction-year] option:selected").val() != val + 30


            ) {
                $("[id^=edit-annee-construction-year] option").eq(1).before($('<option>', {
                    value: val,
                    text: val,
                }));   
            }            
        });
    }

    /// Auto - puissance
    $('.edit-puissance .fieldset-legend').click(function(){
        $('.edit-puissance').addClass('input--filled');
    });

    $('[id^=edit-ch]').on('keyup keypress blur change', function() {
        $('[id^=edit-kw]').val(Math.round(($(this).val())*0.735499));

        if($('[id^=edit-ch]').val() == '') {
            $('[id^=edit-kw]').val('');
        }  else {
            $('.form-item-ch .form-number').removeClass('error');
        }

        if($('[id^=edit-kw]').val() == '') {
            $('[id^=edit-ch]').val('');
        } else {
            $('.form-item-kw .form-number').removeClass('error');
        }

        if($('[id^=edit-ch]').val() == 0) {
            $('[id^=edit-kw]').val('');
        } else {
            $('.form-item-ch .form-number').removeClass('error');
        }

        if($('[id^=edit-kw]').val() == 0) {
            $('[id^=edit-ch]').val('');
        } else {
            $('.form-item-kw .form-number').removeClass('error');
        }

        setTimeout(function(){ 
            if($('.form-item-ch .form-number').hasClass('error')) {
                $('.form-item-kw .form-number').addClass('error');
            } else {
                $('.form-item-kw .form-number').removeClass('error');
            }
        }, 1);
    });

    $('[id^=edit-kw]').on('keyup keypress blur change', function() {
        $('[id^=edit-ch]').val(Math.round(($(this).val())*1.35962));

        if($('[id^=edit-ch]').val() == '') {
            $('[id^=edit-kw]').val('');
        }  else {
            $('.form-item-ch .form-number').removeClass('error');
        }

        if($('[id^=edit-kw]').val() == '') {
            $('[id^=edit-ch]').val('');
        } else {
            $('.form-item-kw .form-number').removeClass('error');
        }

        if($('[id^=edit-ch]').val() == 0) {
            $('[id^=edit-kw]').val('');
        } else {
            $('.form-item-ch .form-number').removeClass('error');
        }

        if($('[id^=edit-kw]').val() == 0) {
            $('[id^=edit-ch]').val('');
        } else {
            $('.form-item-kw .form-number').removeClass('error');
        }

        setTimeout(function(){ 
            if($('.form-item-kw .form-number').hasClass('error')) {
                $('.form-item-ch .form-number').addClass('error');
            } else {
                $('.form-item-ch .form-number').removeClass('error');
            }
        }, 1);
    });

    if($('.edit-puissance .form-item-ch .form-number').hasClass('error')) {
        $('.form-item-kw .form-number').addClass('error');
    } else {
        $('.form-item-kw .form-number').removeClass('error');
    }

    /// Habitation - chambre
    $('.container-piece-inner .inline-input label').each(function(e){
        $(this).next().addBack().wrapAll('<div class="room-infos-container"/>');
    });

    $('.js-form-item-total input').wrap('<div class="input-total"/>');

    //// Chambre total
    var roomVal;
    var otherRoomVal;
    var garageVal;
    var roomTotal;
    var varS = '';

    $('[id^=edit-container-piece-wrapper] select option:selected').each(function(){
       if($(this).val() == 'empty_option') {
           $(this).val(0);
       }
    });

    $('.js-form-item-total .input-total').append("<p>" + 'pièce' + "<span>" + varS + "</span>" + '*' + "</p>");

    function totalRooms() {
        $('.js-form-item-total input').val('');

        roomVal = parseInt($('.js-form-item-chambre-coucher select option:selected').val());

        otherRoomVal = parseInt($('.js-form-item-autre-pieces select option:selected').val());

        garageVal = parseInt($('.js-form-item-garage select option:selected').val());
            
        $('[id^=edit-total]').val(roomVal + otherRoomVal + garageVal + 1);

        roomTotal = $('[id^=edit-total]').val();
     
        if($('.js-form-item-total input').val() > 1) {
            varS = 's';
        } else {
            varS = '';
        }
           
        $('.js-form-item-total .input-total p span').text(varS);

        if($('.js-form-item-total input').val() > 9) {
            $('.js-form-item-total input').css('width','42.5px');
        } else {
            $('.js-form-item-total input').css('width','25px');
        }

        if(roomVal < 19 && $('.error').length == '') {
            $('.js-form-type-range input').val((roomVal * 15000) + 25000);
            $("output").val((roomVal * 15000) + 25000 + '€');
        }
    }

    totalRooms();

    $('[id^=edit-container-piece] select').on('keyup keypress blur change', totalRooms);
    
    /// Habitation - valeur
    $('.js-form-item-chambre-coucher select').on('keyup keypress blur change', function(){
        $('.js-form-type-range output').removeClass('checked');
    });

    /// Range
    $('.js-form-type-range label').next("small").addBack().wrapAll("<div class='range-label-wrapper' />");
    $('.js-form-type-range input').next("output").addBack().wrapAll("<div class='range-input-wrapper' />");

    function rangeChange() {
        $("output").addClass('checked');

        if($("output").hasClass('big')) {
            $("output").removeClass('big');
        }
    }

    $(".form-range")
    .mousedown(function() {
        if(!$("output").hasClass('big')) {
            $("output").addClass('big');
        }
    })

    .mousemove(function() {
    })

    .mouseup(rangeChange);
    

    /// Ajax btn
    $('.webform-button--next').click(function (e) {
        $(this).addClass('ajax-focus');
    });

    // Label animation
    ( function( window ) {
        'use strict';
                
        // class helper functions from bonzo https://github.com/ded/bonzo
        function classReg( className ) {
            return new RegExp("(^|\\s+)" + className + "(\\s+|$)");
        }
                
        // classList support for class management
        // altho to be fair, the api sucks because it won't accept multiple classes at once
        var hasClass, addClass, removeClass;
                
        if ( 'classList' in document.documentElement ) {
            hasClass = function( elem, c ) {
                return elem.classList.contains( c );
            };

            addClass = function( elem, c ) {
                elem.classList.add( c );
            };

            removeClass = function( elem, c ) {
                elem.classList.remove( c );
            };
        }
        else {
            hasClass = function( elem, c ) {
                return classReg( c ).test( elem.className );
            };

            addClass = function( elem, c ) {
                if ( !hasClass( elem, c ) ) {
                elem.className = elem.className + ' ' + c;
                }
            };

            removeClass = function( elem, c ) {
                elem.className = elem.className.replace( classReg( c ), ' ' );
            };
        }
                
        function toggleClass( elem, c ) {
            var fn = hasClass( elem, c ) ? removeClass : addClass;
            fn( elem, c );
        }
                
        var classie = {
            // full names
            hasClass: hasClass,
            addClass: addClass,
            removeClass: removeClass,
            toggleClass: toggleClass,
            // short names
            has: hasClass,
            add: addClass,
            remove: removeClass,
            toggle: toggleClass
        };
                
        // transport
        if ( typeof define === 'function' && define.amd ) {
            // AMD
            define( classie );
        } else {
            // browser global
            window.classie = classie;
        }
                
    })( window );

    (function() {
        // trim polyfill : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/Trim
        if (!String.prototype.trim) {
            (function() {
                // Make sure we trim BOM and NBSP
                var rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
                String.prototype.trim = function() {
                    return this.replace(rtrim, '');
                };
            })();
        }

        [].slice.call( document.querySelectorAll( 'input.required' ) ).forEach( function( inputEl ) {
            // in case the input is already filled..
            if( inputEl.value.trim() !== '' ) {
                classie.add( inputEl.parentNode, 'input--filled' );
            }

            // events:
            inputEl.addEventListener( 'focus', onInputFocus );
            inputEl.addEventListener( 'blur', onInputBlur );
        } );

        function onInputFocus( ev ) {
            classie.add( ev.target.parentNode, 'input--filled' );
            classie.remove( ev.target, 'error' );
        }

        function onInputBlur( ev ) {
            if( ev.target.value.trim() === '' ) {
                classie.remove( ev.target.parentNode, 'input--filled' );
                classie.add( ev.target, 'error' );
            }
        }
    })();

    Drupal.behaviors.myBehavior = {
        attach: function (context, settings) {   


        // UPDATE 27/09/2022
        $("[id^=edit-systeme-alarme] legend").each(function(){
            $(this).wrapAll('<div class="room-infos-container"/>');
        });
    
        $(".room-infos-container").each(function(){
            $(this).next(".fieldset-wrapper").children('small').insertAfter($('.room-infos-container legend'));
        });
    
        //$(".form-element-wrapper .fieldset-wrapper .description").insertAfter($('.room-infos-container legend'));

        // UPDATE 17/08/2022
        var attr;
        attr = $(".webform-progress + .js-form-wrapper").attr('data-drupal-selector');

        var lastDateEncoded = new Date( "01 " + "Jan 2021");
        var dateFrom = lastDateEncoded.getFullYear();
        var dateTo = (new Date).getFullYear();
        var arrDates = Array();
        for(var i = dateFrom; i <= dateTo; i++) arrDates.push(i);

        if(attr == "edit-vos-coordonnees") {
            arrDates.forEach(function(val) {                     
                if($("[id^=edit-permis-year] option:selected").val() != val
                && $("[id^=edit-permis-year] option:selected").val() != val + 1
                && $("[id^=edit-permis-year] option:selected").val() != val + 2
                && $("[id^=edit-permis-year] option:selected").val() != val + 3
                && $("[id^=edit-permis-year] option:selected").val() != val + 4
                && $("[id^=edit-permis-year] option:selected").val() != val + 5
                && $("[id^=edit-permis-year] option:selected").val() != val + 6
                && $("[id^=edit-permis-year] option:selected").val() != val + 7
                && $("[id^=edit-permis-year] option:selected").val() != val + 8
                && $("[id^=edit-permis-year] option:selected").val() != val + 9
                && $("[id^=edit-permis-year] option:selected").val() != val + 10
                && $("[id^=edit-permis-year] option:selected").val() != val + 11
                && $("[id^=edit-permis-year] option:selected").val() != val + 12
                && $("[id^=edit-permis-year] option:selected").val() != val + 13
                && $("[id^=edit-permis-year] option:selected").val() != val + 14
                && $("[id^=edit-permis-year] option:selected").val() != val + 15
                && $("[id^=edit-permis-year] option:selected").val() != val + 16
                && $("[id^=edit-permis-year] option:selected").val() != val + 17
                && $("[id^=edit-permis-year] option:selected").val() != val + 18
                && $("[id^=edit-permis-year] option:selected").val() != val + 19
                && $("[id^=edit-permis-year] option:selected").val() != val + 20
                && $("[id^=edit-permis-year] option:selected").val() != val + 21
                && $("[id^=edit-permis-year] option:selected").val() != val + 22
                && $("[id^=edit-permis-year] option:selected").val() != val + 23
                && $("[id^=edit-permis-year] option:selected").val() != val + 24
                && $("[id^=edit-permis-year] option:selected").val() != val + 25
                && $("[id^=edit-permis-year] option:selected").val() != val + 26
                && $("[id^=edit-permis-year] option:selected").val() != val + 27
                && $("[id^=edit-permis-year] option:selected").val() != val + 28
                && $("[id^=edit-permis-year] option:selected").val() != val + 29
                && $("[id^=edit-permis-year] option:selected").val() != val + 30

                ) {
                    $("[id^=edit-permis-year] option").eq(1).before($('<option>', {
                        value: val,
                        text: val,
                    }));   
                }            
            });

            arrDates.forEach(function(val) {                     
                if($("[id^=edit-date-naissance-year] option:selected").val() != val
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 1
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 2
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 3
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 4
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 5
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 6
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 7
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 8
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 9
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 10
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 11
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 12
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 13
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 14
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 15
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 16
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 17
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 18
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 19
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 20
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 21
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 22
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 23
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 24
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 25
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 26
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 27
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 28
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 29
                && $("[id^=edit-date-naissance-year] option:selected").val() != val + 30

                ) {
                    $("[id^=edit-date-naissance-year] option").eq(1).before($('<option>', {
                        value: val,
                        text: val,
                    }));   
                }            
            });
        }

        if(attr == "edit-votre-vehicule") {
            arrDates.forEach(function(val) {                     
                if($("[id^=edit-annee-construction-year] option:selected").val() != val
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 1
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 2
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 3
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 4
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 5
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 6
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 7
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 8
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 9
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 10
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 11
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 12
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 13
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 14
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 15
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 16
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 17
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 18
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 19
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 20
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 21
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 22
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 23
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 24
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 25
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 26
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 27
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 28
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 29
                && $("[id^=edit-annee-construction-year] option:selected").val() != val + 30


                ) {
                    $("[id^=edit-annee-construction-year] option").eq(1).before($('<option>', {
                        value: val,
                        text: val,
                    }));   
                }            
            });
        }
            
            // Form 
            /// Ajax btn
            $('.webform-button--next').click(function (e) {
                $(this).addClass('ajax-focus');
            });

            $('.webform-button--submit').click(function (e) {
                $(this).addClass('ajax-focus');
            });
            
            
            /// Put "Etape" text in progress sidebar
            $('form .form-actions').insertAfter($('form .js-form-wrapper .container-bg-white'));

            stepNumberActif = $('.progress-step.is-active .progress-marker').attr('data-text');

            $('.progress-marker').each(function (e) {
                var stepNumber = $(this).attr('data-text');

                $(this).append("<p>" + 'Etape 0' + stepNumber + "<span class='ontotal-step d-md-none'> / 0" + totalStep + "</span>" + "</p>");
            });

            if($('.progress-step:last-child').hasClass('is-active')){
                $('body').addClass('form-last-step');
            } else {
                $('body').removeClass('form-last-step');
            }

            var progressionWidth = (stepNumberActif / totalStep) * 100;

            $( "<style>.large-container-form:before {width: " + progressionWidth + "%;}</style>" ).appendTo("head");

            /// Add focus on select option:selected
            function requiredField() {
                if($(this).find('option:selected').val() != '') {
                    $(this).addClass('focus');
                    $(this).removeClass('error');
                } else {
                    $(this).removeClass('focus');
                    $(this).addClass('error');
                }
            }
            
            $('select').each(function (e) {
                if($(this).find('option:selected').val() != '') {
                    $(this).addClass('focus');
                    $(this).removeClass('error');
                }
            });

            $('select').each(function (e) {
                $(this).on("keydown keyup change",requiredField);
            });

            $('.js-form-type-select').each(function (e) {
                if($(this).find('select').hasClass('error')) {
                    $(this).addClass('error');
                }
            });

            $('.js-form-type-select select').click(function (e) {
                $(this).parent().addClass('input--filled');
            });

            $('.js-form-type-select select').each(function (e) {
                if($(this).find('option:selected').val() != '') {
                    $(this).parent().addClass('input--filled');
                }
            });

            $('.js-form-type-select select').click(function (e) {
                $(this).parents('.js-form-type-select').addClass('input--filled');
            });

            if($('.form-item-annee-construction select').find('option:selected').val() != '') {
                $('.form-item-annee-construction').addClass('input--filled');
            }

            requiredField;

            /// Checkbox
            if($('form .long-description').length > 0) {
                $('.long-description .form-check > label').each(function (e) {
                    $(this).append('<span></span>');
                });
            }

            if($('.js-webform-checkboxes').length > 0) {
                $('.js-webform-checkboxes .form-check .form-check-label').each(function (e) {
                    $(this).append('<span></span>');
                });
            }

            $('[id^=edit-formules-omnium-complte]').click(function(){
                if($(this).is(':checked') && !$('[id^=edit-formules-omnium-partielle]').is(':checked')) {
                    $('[id^=edit-formules-omnium-partielle]').trigger('click');
                }
            });

            $('[id^=edit-formules-omnium-complte]').on("change", function() {
                if($('[id^=edit-formules-omnium-complte]').is(':checked')) {
                    $('[id^=edit-formules-omnium-partielle]').css('pointer-events', 'none');
                    $('[id^=edit-formules-omnium-partielle] + .option').css('pointer-events', 'none');
                    $('[id^=edit-formules-omnium-partielle]').parent().css('pointer-events', 'none');

                } else {
                    $('[id^=edit-formules-omnium-partielle]').css('pointer-events', 'auto');
                    $('[id^=edit-formules-omnium-partielle] + .option').css('pointer-events', 'auto');
                    $('[id^=edit-formules-omnium-partielle]').parent().css('pointer-events', 'auto');
                }
            });

            /// Radio
            $('.form-check input.form-radio').each(function (e) {
                $(this).insertBefore($(this).parent());
            });

            $(".js-webform-type-radios").each(function(index) {
                $(this).wrapInner("<div class='form-element-wrapper' />")
            });

            $('.js-webform-type-radios').each(function (e) {
                $(this).find('input').click(function(){
                    $(this).parentsUntil('.js-webform-type-radios').find('input').removeClass('error');
                });
            }); 

            /// Date selects
            $('.date-select > label').click(function(){
                $(this).parent().addClass('input--filled');
            });

            $('.date-select select').on('keyup keypress blur change', function (e) {
                $(this).closest('.date-select').addClass('input--filled');
            });

            $('.date-select select').each(function (e) {
                $(this).focusout(function (e) {
                    if($(this).find('option:selected').val() == '') {
                        $(this).addClass('error');
                    } else {
                        $(this).removeClass('error');
                    }
                });
            });

            if($('.error').length == 0) {
                $('.date-select').addClass('input--filled');
            }

            $('.date-select').each(function (e) {
                var errorNumber = $(this).find('select.error').length;

                if(errorNumber < 2) {
                    $(this).addClass('input--filled');
                }
            });
            
            $('.date-select').each(function (e) {
                if($(this).find('select').hasClass('error')) {
                    $(this).addClass('error');
                }
            });

            /// Auto - puissance
            $('.edit-puissance .fieldset-legend').click(function(){
                $('.edit-puissance').addClass('input--filled');
            });

            $('.edit-puissance input').on('keyup keypress blur change', function (e) {
                $(this).closest('.edit-puissance').addClass('input--filled');
            });
            
            if($('.edit-puissance .form-item-kw .form-number').val() != '' || $('.edit-puissance .form-item-ch .form-number').val() != '') {
                $('.edit-puissance').addClass('input--filled');
            }
            
            $('[id^=edit-ch]').on('keyup keypress blur change', function() {
                $('[id^=edit-kw]').val(Math.round(($(this).val())*0.735499));
        
                if($('[id^=edit-ch]').val() == '') {
                    $('[id^=edit-kw]').val('');
                }  else {
                    $('.form-item-ch .form-number').removeClass('error');
                }
        
                if($('[id^=edit-kw]').val() == '') {
                    $('[id^=edit-ch]').val('');
                } else {
                    $('.form-item-kw .form-number').removeClass('error');
                }
        
                if($('[id^=edit-ch]').val() == 0) {
                    $('[id^=edit-kw]').val('');
                } else {
                    $('.form-item-ch .form-number').removeClass('error');
                }
        
                if($('[id^=edit-kw]').val() == 0) {
                    $('[id^=edit-ch]').val('');
                } else {
                    $('.form-item-kw .form-number').removeClass('error');
                }
        
                setTimeout(function(){ 
                    if($('.form-item-ch .form-number').hasClass('error')) {
                        $('.form-item-kw .form-number').addClass('error');
                    } else {
                        $('.form-item-kw .form-number').removeClass('error');
                    }
                }, 1);
            });

            $('[id^=edit-kw]').on('keyup keypress blur change', function() {
                $('[id^=edit-ch]').val(Math.round(($(this).val())*1.35962));
        
                if($('[id^=edit-ch]').val() == '') {
                    $('[id^=edit-kw]').val('');
                }  else {
                    $('.form-item-ch .form-number').removeClass('error');
                }
        
                if($('[id^=edit-kw]').val() == '') {
                    $('[id^=edit-ch]').val('');
                } else {
                    $('.form-item-kw .form-number').removeClass('error');
                }
        
                if($('[id^=edit-ch]').val() == 0) {
                    $('[id^=edit-kw]').val('');
                } else {
                    $('.form-item-ch .form-number').removeClass('error');
                }
        
                if($('[id^=edit-kw]').val() == 0) {
                    $('[id^=edit-ch]').val('');
                } else {
                    $('.form-item-kw .form-number').removeClass('error');
                }
        
                setTimeout(function(){ 
                    if($('.form-item-kw .form-number').hasClass('error')) {
                        $('.form-item-ch .form-number').addClass('error');
                    } else {
                        $('.form-item-ch .form-number').removeClass('error');
                    }
                }, 1);
            });

            
        
            if($('.edit-puissance .form-item-ch .form-number').hasClass('error')) {
                $('.form-item-kw .form-number').addClass('error');
            } else {
                $('.form-item-kw .form-number').removeClass('error');
            }

            if($('.edit-puissance .form-item-kw .form-number').hasClass('error') || $('.edit-puissance .form-item-ch .form-number').hasClass('error')) {
                 $('.edit-puissance').addClass('error');
            }

            /// Habitation - chambre
            $('.container-piece-inner .inline-input label').each(function(e){
                $(this).next().addBack().wrapAll('<div class="room-infos-container"/>');
            });

            $('.js-form-item-total input').wrap('<div class="input-total"/>');

            //// Chambre total
            var roomVal;
            var otherRoomVal;
            var garageVal;
            var roomTotal;
            var varS = '';

            $('[id^=edit-container-piece-wrapper] select option:selected').each(function(){
            if($(this).val() == 'empty_option') {
                $(this).val(0);
            }
            });

            $('.js-form-item-total .input-total').append("<p>" + 'pièce' + "<span>" + varS + "</span>" + '*' + "</p>");

            function totalRooms() {
                $('.js-form-item-total input').val('');

                roomVal = parseInt($('.js-form-item-chambre-coucher select option:selected').val());

                otherRoomVal = parseInt($('.js-form-item-autre-pieces select option:selected').val());

                garageVal = parseInt($('.js-form-item-garage select option:selected').val());
                    
                $('[id^=edit-total]').val(roomVal + otherRoomVal + garageVal + 1);

                roomTotal = $('[id^=edit-total]').val();
            
                if($('.js-form-item-total input').val() > 1) {
                    varS = 's';
                } else {
                    varS = '';
                }
                
                $('.js-form-item-total .input-total p span').text(varS);

                if($('.js-form-item-total input').val() > 9) {
                    $('.js-form-item-total input').css('width','42.5px');
                } else {
                    $('.js-form-item-total input').css('width','25px');
                }

                if(roomVal < 19 && $('.error').length == '') {
                    $('.js-form-type-range input').val((roomVal * 15000) + 25000);
                    $("output").val((roomVal * 15000) + 25000 + '€');
                }
            }

            totalRooms();

            $('[id^=edit-container-piece] select').on('keyup keypress blur change', totalRooms);

            /// Habitation - valeur
            $('.js-form-item-chambre-coucher select').on('keyup keypress blur change', function(){
                $('.js-form-type-range output').removeClass('checked');
            });

            /// Range
            $('.js-form-type-range label').next("small").addBack().wrapAll("<div class='range-label-wrapper' />");
            $('.js-form-type-range input').next("output").addBack().wrapAll("<div class='range-input-wrapper' />");

            function rangeChange() {
                $("output").addClass('checked');

                if($("output").hasClass('big')) {
                    $("output").removeClass('big');
                }
            }

            $(".form-range")
            .mousedown(function() {
                if(!$("output").hasClass('big')) {
                    $("output").addClass('big');
                }
            })

            .mousemove(function() {
            })

            .mouseup(rangeChange);


            /// Actions button
            if($('.webform-button--previous').length > 0) {
                $('.form-actions').css('justify-content','space-between');
            }

            

            // Label animation
            ( function( window ) {
                'use strict';
                
                // class helper functions from bonzo https://github.com/ded/bonzo
                
                function classReg( className ) {
                return new RegExp("(^|\\s+)" + className + "(\\s+|$)");
                }
                
                // classList support for class management
                // altho to be fair, the api sucks because it won't accept multiple classes at once
                var hasClass, addClass, removeClass;
                
                if ( 'classList' in document.documentElement ) {
                hasClass = function( elem, c ) {
                    return elem.classList.contains( c );
                };
                addClass = function( elem, c ) {
                    elem.classList.add( c );
                };
                removeClass = function( elem, c ) {
                    elem.classList.remove( c );
                };
                }
                else {
                hasClass = function( elem, c ) {
                    return classReg( c ).test( elem.className );
                };
                addClass = function( elem, c ) {
                    if ( !hasClass( elem, c ) ) {
                    elem.className = elem.className + ' ' + c;
                    }
                };
                removeClass = function( elem, c ) {
                    elem.className = elem.className.replace( classReg( c ), ' ' );
                };
                }
                
                function toggleClass( elem, c ) {
                var fn = hasClass( elem, c ) ? removeClass : addClass;
                fn( elem, c );
                }
                
                var classie = {
                // full names
                hasClass: hasClass,
                addClass: addClass,
                removeClass: removeClass,
                toggleClass: toggleClass,
                // short names
                has: hasClass,
                add: addClass,
                remove: removeClass,
                toggle: toggleClass
                };
                
                // transport
                if ( typeof define === 'function' && define.amd ) {
                // AMD
                define( classie );
                } else {
                // browser global
                window.classie = classie;
                }
                
            })( window );

            (function() {
                // trim polyfill : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/Trim
                if (!String.prototype.trim) {
                    (function() {
                        // Make sure we trim BOM and NBSP
                        var rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
                        String.prototype.trim = function() {
                            return this.replace(rtrim, '');
                        };
                    })();
                }
        
                [].slice.call( document.querySelectorAll( 'input.required' ) ).forEach( function( inputEl ) {
                    // in case the input is already filled..
                    if( inputEl.value.trim() !== '' ) {
                        classie.add( inputEl.parentNode, 'input--filled' );
                    }
        
                    // events:
                    inputEl.addEventListener( 'focus', onInputFocus );
                    inputEl.addEventListener( 'blur', onInputBlur );
                } );
        
                function onInputFocus( ev ) {
                    classie.add( ev.target.parentNode, 'input--filled' );
                    classie.remove( ev.target, 'error' );
                }
        
                function onInputBlur( ev ) {
                    if( ev.target.value.trim() === '' ) {
                        classie.remove( ev.target.parentNode, 'input--filled' );
                        classie.add( ev.target, 'error' );
                    }
                }
            })();
        }
    }
});
