import '@babel/polyfill';
import '@/scss/app.scss'
import 'bootstrap';
import '@fortawesome/fontawesome-free';
import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import '@fortawesome/fontawesome-free/scss/solid.scss';
import '@fortawesome/fontawesome-free/scss/regular.scss';
import '@fortawesome/fontawesome-free/scss/brands.scss';
//import '@fortawesome/fontawesome-free/scss/light.scss';


if (module.hot) {
  module.hot.accept()
}
